<template>
  <asterix-application-layout>
    <template #sidebar>
      <asterix-sidebar v-model="isSidebarOpen">
        <sun-sidebar-group class="mt-8">
          <sun-sidebar-nav-item :to="analytics">
            <template #icon><analytics-svg class="w-4" /></template>
            <span class="font-bold">Analytics</span>
            <template #children>
              <sun-sidebar-nav-item :to="economics"> Economics </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="branding"> Branding Web </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="ott"> OTT </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="youtube"> Youtube </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="performance"> Performance </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="skin"> Skin + Ignite </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="precio"> Precio </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="content"> Content </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="kleup"> Kleup </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="others"> Others </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>

          <sun-sidebar-nav-item :to="campaignManagerList">
            <template #icon><category-svg class="w-4" /></template>
            <span class="font-bold">Campaign Manager</span>
            <template #children>
              <sun-sidebar-nav-item :to="campaignManagerList"> List All </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="campaignManagerToday"> Finish Today </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="campaignManagerTomorrow"> Finish Tomorrow </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="campaignManagerStarting"> Starting Today </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="campaignManagerStartingYesterday"> Starting Yesterday </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>

          <sun-sidebar-nav-item :to="connection">
            <template #icon><platform-svg class="w-4" /></template>
            <span class="font-bold">Platforms</span>
          </sun-sidebar-nav-item>

          <sun-sidebar-nav-item :to="admin">
            <template #icon><admin-svg class="w-4" /></template>
            Admin
            <template #children>
              <sun-sidebar-nav-item :to="users"> Users </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="scheduledEmails"> Scheduled Emails </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>
        </sun-sidebar-group>
      </asterix-sidebar>
    </template>

    <template #header>
      <asterix-topbar class="z-40" @change="isSidebarOpen = !isSidebarOpen" />
    </template>
  </asterix-application-layout>
</template>

<script>
import AsterixApplicationLayout from '@/components/templates/AsterixApplicationLayout';
import AsterixSidebar from '@/components/molecules/shared/AsterixSidebar/AsterixSidebar';
import AsterixTopbar from '@/components/molecules/shared/AsterixTopbar';
import SunSidebarNavItem from '@/components/molecules/SunSidebarNavItem';
import AnalyticsSvg from '@/components/icons/AnalyticsSvg';
import CategorySvg from '@/components/icons/CategorySvg';
import PlatformSvg from '@/components/icons/PlatformSvg';
import AdminSvg from '@/components/icons/AdminSvg';
import { platforms } from '@/router/private/modules/thirdParty/platforms/index.js';
import { connection } from '@/router/private/modules/thirdParty/platforms/connection';
import { cost } from '@/router/private/modules/thirdParty/platforms/cost';
import { analytics } from '@/router/private/modules/thirdParty/analytics';
import { admin } from '@/router/private/modules/thirdParty/admin/index.js';
import { users } from '@/router/private/modules/thirdParty/admin/users';
import { demand } from '@/router/private/modules/thirdParty/demand/index.js';
import { advertiser } from '@/router/private/modules/thirdParty/demand/advertiser';
import { product } from '@/router/private/modules/thirdParty/demand/product';
import { economics } from '@/router/private/modules/thirdParty/analytics/economics.js';
import { branding } from '@/router/private/modules/thirdParty/analytics/branding.js';
import { ott } from '@/router/private/modules/thirdParty/analytics/ott.js';
import { youtube } from '@/router/private/modules/thirdParty/analytics/youtube.js';
import { performance } from '@/router/private/modules/thirdParty/analytics/performance.js';
import { others } from '@/router/private/modules/thirdParty/analytics/others.js';
import { skin } from '@/router/private/modules/thirdParty/analytics/skin.js';
import { scheduledEmails } from '@/router/private/modules/thirdParty/admin/scheduledEmails';
import { content } from '@/router/private/modules/thirdParty/analytics/content.js';
import { kleup } from '@/router/private/modules/thirdParty/analytics/kleup.js';
import { precio } from '@/router/private/modules/thirdParty/analytics/precio.js';
import { campaignManagerList } from '@/router/private/modules/thirdParty/campaignManager/campaignManagerList.js';
import { campaignManagerToday } from '@/router/private/modules/thirdParty/campaignManager/campaignManagerToday.js';
import { campaignManagerTomorrow } from '@/router/private/modules/thirdParty/campaignManager/campaignManagerTomorrow.js';
import { campaignManagerStarting } from '@/router/private/modules/thirdParty/campaignManager/campaignManagerStarting.js';
import { campaignManagerStartingYesterday } from '@/router/private/modules/thirdParty/campaignManager/campaignManagerStartingYesterday.js';

export default {
  name: 'ThirdPartyIndex',
  components: {
    AsterixApplicationLayout,
    AsterixSidebar,
    AsterixTopbar,
    SunSidebarNavItem,
    AnalyticsSvg,
    CategorySvg,
    PlatformSvg,
    AdminSvg,
    // DemandSvg,
  },
  data() {
    return {
      isSidebarOpen: true,
      platforms,
      analytics,
      connection,
      cost,
      admin,
      users,
      demand,
      advertiser,
      product,
      economics,
      branding,
      performance,
      others,
      ott,
      youtube,
      campaignManagerList,
      skin,
      scheduledEmails,
      content,
      kleup,
      precio,
      campaignManagerToday,
      campaignManagerTomorrow,
      campaignManagerStarting,
      campaignManagerStartingYesterday,
    };
  },
};
</script>

<style scoped>
:deep(.body-layout) {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  :deep(#layout) {
    padding-left: 8rem;
  }
  :deep(#sidebar) {
    width: 8rem;
  }
}
:deep(.sidebar-item-content div) {
  padding-left: 3px;
  padding-right: 3px;
}
:deep(.sidebar-item-children .sidebar-item .sidebar-item-content) {
  padding-left: 0.3rem;
}
</style>
